import React from 'react';
import { Layout } from '../components/layout';
import { Hero } from '@/components/layout/header/hero';
import { StaticImage } from 'gatsby-plugin-image';

export default function Bicipark() {
  const pageTitle = 'Bicipark';

  const Banner = () => (
    <StaticImage
      src="../images/banners/bicipark.png"
      alt={pageTitle}
      quality={100}
    />
  );

  const Left = () => (
    <StaticImage
      src="../images/other/bicipark.png"
      quality={100}
      alt="bicipark"
    />
  );

  const Right = () => (
    <div>
      <div>
        <h2 className="h3 text-primary font-rubik">Bicipark Stazione</h2>
        <p className="text-secondary font-oxygen mt-3 mb-5">
          Il Bicipark Stazione è un parcheggio custodito per biciclette a
          disposizione di cittadini e turisti nel piazzale della stazione e può
          contenere 230 biciclette
        </p>
      </div>
      <div>
        <h2 className="h3 text-primary font-rubik">Bicipark Ponte San Paolo</h2>
        <p className="text-secondary font-oxygen mt-3 mb-5">
          Bicipark Ponte San Paolo è un parcheggio custodito per biciclette a
          disposizione della cittadinanza in contra' Pescaria, al quale si
          accede da un cancello affacciato su largo Neri Pozza, vicino a ponte
          San Paolo. Lo spazio del Bicipark ponte San Paolo può contenere 70
          biciclette.
          <br />
          <br />
          Presso i parcheggi di biciclette, oltre all'attività di custodia, sono
          previste, nell'ottica di promuovere la cultura e l’uso della
          bicicletta, le seguenti attività:
          <br />
          <br />- servizi di ciclo-officina,
          <br />- servizio di marcatura delle biciclette,
          <br />- attività di vendita biciclette usate,
          <br />- servizio di noleggio biciclette.
        </p>
      </div>
      <div className="d-flex flex-row">
        <div className="d-flex me-3">
          <a href="https://maps.app.goo.gl/UedmmCgvSCzhvemB6" target="_blank">
            <button className="btn btn-primary mb-3">Trova Bicipark</button>
          </a>
        </div>
        <div className="d-flex">
          <a
            href="https://www.comune.vicenza.it/vicenza/turismo.php/muoversi_a_vicenza/vi.bicipark_ponte_san_paolo"
            target="_blank"
          >
            <button className="btn btn-primary mb-3">Maggiori Info</button>
          </a>
        </div>
      </div>
    </div>
  );

  return (
    <Layout title={pageTitle}>
      <Hero title={pageTitle} banner={<Banner />} />
      <div className="container mt-5 mb-5">
        <div className="row gx-5">
          <div className="col-12 col-lg-5">
            <Left />
          </div>
          <div className="col-12 col-lg-7 mt-3">
            <Right />
          </div>
        </div>
      </div>
    </Layout>
  );
}
